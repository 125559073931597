import React, { useState,  useRef, useMemo } from "react";
import { Box, Paper } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import coverage from "../data/charts/unicorn2.json";
import { useTheme } from "@mui/material/styles";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { useMediaQuery } from "@mui/material";
import ButtonExport from "./ButtonExport.jsx";
import { ConnectingAirportsOutlined } from "@mui/icons-material";


function TableCapTable({filteredData, companyName}) {

  const theme = useTheme();
  const [rowData] = useState(coverage);
  const gridRef = useRef(null); // Reference for the AG Grid
  
  const isTablet = useMediaQuery('(max-width:800px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed

  const [selectedColumns, setSelectedColumns] = useState(isMobile?[
    "announcementDate",
    "postMoney",
    "investment",
  ]:[
    "announcementDate",
    "series",
    "postMoney",
    "investment",
    "preMoney",
    "sourceLink",
  ]);
  const employeesCellRenderer = (params) => {
    const employees = params.value;
    return employees ? employees.toLocaleString() : '';
  };

  const arrCellRenderer = (params) => {
    let valuation = params.value;
    
    // Check if the valuation is valid
    if (valuation === null || valuation === undefined || isNaN(valuation)) {
      return "-";
    }
    
    // Convert to billions or millions as necessary
      if (valuation > 1000000000){
        valuation = (valuation / 1000000000).toFixed(1); // Rounds to 1 decimal for millions
        return `$${valuation.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}B`;
      }
      else{

     
      valuation = (valuation / 1000000).toFixed(0); // Rounds to 1 decimal for millions
      return `$${valuation.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}M`;
    }
  };
  const numericalComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    if ((valueA === null|| valueA === undefined) && (valueB === null|| valueB === undefined)) {
        return 0;
    }
    if (valueA === null|| valueA === undefined) {
        return isInverted ? -1 : 1;
    }
    if (valueB === null|| valueB === undefined) {
        return isInverted ? 1 : -1;
    }
  
    return valueA - valueB;
  }
  const dateComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    const dateA = new Date(valueA);
    const dateB = new Date(valueB);
  
    // Handle null, undefined, or invalid dates
    if ((isNaN(dateA) && isNaN(dateB)) || (!valueA && !valueB)) {
      return 0;
    }
    if (isNaN(dateA) || !valueA) {
      return isInverted ? -1 : 1;
    }
    if (isNaN(dateB) || !valueB) {
      return isInverted ? 1 : -1;
    }
  
    // Compare the dates
    return dateA - dateB;
  };
  const dateFormater =(params)=>{
      if (params.value === null || params.value === undefined ) {
        return "-";
      }
        const date = new Date(params.value);
        return date.toLocaleDateString(); 
  
  }
  const sourceLinkCellRenderer = (params) => {
    const url = params.value;
    
    // If the URL is invalid or empty, return a placeholder or "-"
    if (!url || url === null || url === undefined) {
      return "-";
    }

    // Use URL constructor to extract hostname (domain)
    let hostname;
    try {
      hostname = new URL(url).hostname;
    } catch (error) {
      return "-"; // If URL parsing fails, return "-"
    }

    // Remove "www." prefix if it exists
    if (hostname.startsWith("www.")) {
      hostname = hostname.substring(4);
    }

    // Return a React component for rendering the link with the primary theme color
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: theme.palette.primary.main }} // Set link color to primary color
      >
        {hostname}
      </a>
    );
  };
  const valuationCellRenderer = (params) => {
    let valuation = params.value;
    
    // Check if valuation is valid
    if (valuation === null || valuation === undefined || isNaN(valuation)) {
      return "-";
    }
  
    if (valuation >= 1000000000) {
      // If the value is greater than or equal to $1B, format it in billions
      valuation = (valuation / 1000000000).toFixed(1); // Rounds to 1 decimal for billions
      return `$${valuation.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}B`;
    } else if (valuation >= 100000000) {
      // If the value is between $100M and $1B, format it in millions without decimals
      valuation = (valuation / 1000000).toFixed(0); // No decimal places for values above $100M
      return `$${valuation.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}M`;
    } else {
      // If the value is less than $100M, format it with one decimal place
      valuation = (valuation / 1000000).toFixed(1); // Rounds to 1 decimal for millions
      return `$${valuation.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}M`;
    }
  };
const allColDefs = [
  { field: "announcementDate", headerName:"Announced Date",filter: "agDateColumnFilter",
    

    valueFormatter: dateFormater,
    filterParams: {
      // Custom comparator for date filtering
      comparator: (filterDate, cellValue) => {
          // Ensure both values are valid dates
          if (!filterDate || !cellValue) return 0; // Not comparable
          const filterDateObj = new Date(filterDate);
          const cellDateObj = new Date(cellValue);
          
          if (cellDateObj < filterDateObj) return -1; // Cell date is before filter date
          if (cellDateObj > filterDateObj) return 1;  // Cell date is after filter date
          return 0; // Dates are equal
      }
  },
  floatingFilter: false, maxWidth: 180,width:120, minWidth: 100 },
  { field: "series", headerName:"Funding Round", filter: "agTextColumnFilter", floatingFilter: false, maxWidth: 200,width:150, minWidth: 90, sortingOrder: ["desc", "asc", null] },
  { field: "postMoney", headerName:"Post-Money Valuation", filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: valuationCellRenderer, maxWidth: 180,width:120, minWidth: 90, sortingOrder: ["desc", "asc", null] },
  { field: "investment", headerName:"Amount Raised", filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: valuationCellRenderer, maxWidth: 180,width:120, minWidth: 90, sortingOrder: ["desc", "asc", null] },
  { field: "preMoney", headerName:"Pre-Money Valuation", filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: valuationCellRenderer, maxWidth: 180,width:120, minWidth: 90, sortingOrder: ["desc", "asc", null] },
  { field: "sourceLink", headerName:"Source Link", filter: "agNumberColumnFilter", floatingFilter: false, cellRenderer: sourceLinkCellRenderer,  maxWidth: 300,width:180, minWidth: 90, sortingOrder: ["desc", "asc", null] },
];
  // Group columns by their 'group' property

  const filteredColDefs = useMemo(() => { 
    return allColDefs.filter((colDef) => selectedColumns.includes(colDef.field));
  }, [selectedColumns,theme.palette.mode]);

  const tableWidth = Math.max(
    filteredColDefs.reduce((sum, colDef) => sum + colDef.width, 0) + 5,
    500
  );
  const defaultColDef = useMemo(() => ({
    filter: true,
    floatingFilter: false,
    sortable: true,
    resizable: true,
    suppressMenuHide: false,
    suppressHeaderMenuButton: false,
  }), []);
  const tableHeight = filteredData.length *30 + 62
  return (
    <Box sx={{ height: '100%', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>

  
  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Box sx={{ 
      display: 'flex', 
      justifyContent:'space-between',
      paddingBottom: '6px', 
      width: tableWidth, 
      maxWidth: "98%", 
      color: theme.palette.primary.main, 
      fontSize: '24px', 
      fontWeight: '300 ' , // Use !important to enforce the style
      textAlign: 'left', // Ensure text is left-aligned
      paddingLeft: '0.5%',
  }}
  style={{ fontWeight: '300' }} // Inline style for testing
  >
    Cap Table
    <ButtonExport
    
    fileName={`${companyName} SoftwareIQ Cap Table`}
    gridRef={gridRef}
    />
  </Box>
  <Paper
    className={theme.palette.mode === 'dark' ? "ag-theme-material-dark" : "ag-theme-material"}
    style={{
      width: tableWidth, // Ensure Paper fills the available space
      maxWidth: "98%", // Limit max width to the desired table width
    "--ag-odd-row-background-color": theme.tableHeader.highlight,
    "--ag-row-hover-color": theme.tableHeader.highlightDark,
    "--ag-material-primary-color": theme.palette.primary.main,
    "--ag-material-accent-color": theme.palette.primary.main,    
    "--ag-header-cell-color": "theme.palette.text.secondary", // For text color
    "--ag-header-height": "50px", // Optional: Adjusts the header height
    height:'100%',
    maxHeight: tableHeight, // Ensure this is 100% of the parent container's height


    }}
    sx={{
      '& .ag-header-cell': {
        backgroundColor: theme.table.header,
        color: theme.palette.text.secondary,
        letterSpacing:"normal",
        wordSpacing:"normal",
        whiteSpace: "normal", // Allow text wrapping
        lineHeight: "1.2em", // Adjust line height for better readability
        textAlign: 'center', // Optional: Center text if needed
        padding: '0px 10px', // Adjust padding as needed (top/bottom left/right)

      },
      '& .ag-header-cell-text': {
        letterSpacing:"normal",
        textTransform:"none",
        wordSpacing:"normal",
        whiteSpace: "normal", // Allow text wrapping
      },
    }}
  >
    <AgGridReact
      ref={gridRef}
      rowData={filteredData}
      columnDefs={filteredColDefs}
      defaultColDef={defaultColDef}
      headerHeight={50}
      rowHeight={30}
    />
  </Paper>
</Box>
</Box>
  );
}

export default TableCapTable;