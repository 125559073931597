import React, { useState, useEffect } from 'react';
import '../index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {FaGitAlt,FaUserSecret,FaCloud,FaCogs,FaStore,FaDatabase, FaIndustry, FaDesktop, FaBuilding,FaCoins,FaFileInvoice,FaComments,FaCode,FaServer,FaShieldAlt, FaUsers,FaHammer,FaNetworkWired} from 'react-icons/fa';

import {Icon,useMediaQuery,Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow, Box, Typography,Collapse,IconButton } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';




const SecurityAnalysisAbout = ({ticker, open, handleToggleCollapse}) => {
  useEffect(() => {
    window.scrollTo(0, 0);


  }, []); 
  const category = [
    {
      id: 1,
      subIndustry:'Application',
      filter: 'Application',
      label: 'Application Software',
      icon: <FaDesktop />,
    },
    {
      id: 10,
      subIndustry:'Application',
      filter: 'CRM, Marketing, and Support',
      label: 'CRM, Marketing & Support',
      icon: <FaStore />,
    },
    {
      id: 11,
      subIndustry:'Application',
      filter: 'HR, Finance & Supply Chain',
      label: 'HR, Finance & SCM',
      icon: <FaUsers />,
    },
    {
      id: 12,
      subIndustry:'Application',
      filter: 'Productivity & Content Creation',
      label: 'Productivity',
      icon: <FaFileInvoice />,
    },
    {
      id: 2,
      subIndustry:'Infrastructure',
      filter: 'Infrastructure',
      label: 'Infrastructure Software',
      icon: <FaCogs />,
    },
    
    {
      id: 20,
      subIndustry:'Infrastructure',
      filter: 'Database & Data Platforms',
      label: 'Database & Data Platforms',
      icon: <FaDatabase />,
    },
    {
      id: 21,
      subIndustry:'Infrastructure',
      filter: 'Dev Tools & Low Code',
      label: 'Dev Tools & Low Code',
      icon: <FaCode />,
    },
    {
      id: 22,
      subIndustry:'Infrastructure',
      filter: 'IT Operations',
      label: 'IT Operations',
      icon: <FaServer />,
    },
    {
      id: 23,
      subIndustry:'Infrastructure',
      filter: 'Security & Endpoint Management',
      label: 'Security & UEM',
      icon: <FaShieldAlt />,
    },
    {
      id: 24,
      subIndustry:'Infrastructure',
      filter: 'Networking & CDN',
      label: 'Networking & CDN',
      icon: <FaNetworkWired />,
    },
    {
      id: 25,
      subIndustry:'Infrastructure',
      filter: 'CPaaS',
      label: 'CPaaS',
      icon: <FaComments />,
    },
    {
      id: 3,
      subIndustry:'Vertical',
      filter: 'Vertical',
      label: 'Vertical Software',
      icon: <FaIndustry />,
    },
    {
      id: 30,
      subIndustry:'Vertical',
      filter: 'Financials',
      label: 'Financials',
      icon: <FaCoins />,
    },
    {
      id: 31,
      subIndustry:'Vertical',
      filter: 'Engineering & Industrials',
      label: 'Engineering & Industrials',
      icon: <FaHammer />,
    },
    {
      id: 32,
      subIndustry:'Vertical',
      filter: 'Other Vertical',
      label: 'Other Vertical',
      icon: <FaIndustry />,
    },
    {
      id: 4,
      subIndustry:'Diversified',
      filter: 'Diversified',
      label: 'Diversified Software',
      icon: <FaBuilding />,
    },
  ];
  const findIcon = (subIndustry, filter) => {
    const categoryItem = category.find(item => item.subIndustry === subIndustry && item.filter === filter);
    return categoryItem ? categoryItem.icon : null; // Return the icon or null if not found
  };
  const shouldWrap2 = useMediaQuery('(max-width: 600px)');
  
  const theme = useTheme();
  const [table, setTable] = useState([]);
  
  const [keyStats, setKeyStats] = useState([]);
  const tableContainerWidth = 1100

  useEffect(() => {
    setTable([])
    const fetchData = async () => {
      try {
        // Ensure ticker is a string and log it for debugging
        const dynamicTable = await import(`../data/stockpage/about/${ticker}.json`);
        const dynamicKeyStats = await import(`../data/stockpage/keystatistics/${ticker}.json`);
        const keyStatsFilterCriteria = ['Current Status','Acquirer(s)','Close Date','Employees', 'Year Founded','Market Cap','Enterprise Value','Last Raise Date','Series','Latest Valuation','Total Equity Raised']; // Example metrics to include

        

        // Set the data in state, assuming dynamicData.default is an array of arrays as per provided data format
          setTable(dynamicTable.default);
          if (Array.isArray(dynamicKeyStats.default[0].keyStats)) {
            setKeyStats(dynamicKeyStats.default[0].keyStats.filter(item =>
              keyStatsFilterCriteria.includes(item.field)));
          }

      } catch (error) {
        console.error(`Error fetching data for ${ticker}:, error`);
      }
    }

    fetchData();
  }, [ticker]);

  

  
  const isTablet = useMediaQuery('(max-width:800px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  // Function to handle button clicks and update the selected button state
  
  
  const descriptionParagraphs = (table.Description || '')
    .replace(/[\u2018\u2019\u201B\uFFFD]/g, "'") // Normalize non-standard apostrophes
    .split('\n') // Split by new lines for paragraphs
    .filter(paragraph => paragraph.trim() !== ''); // Remove empty paragraphs

  const categoryIcon = findIcon(table["Sub Industry"], table["Category"]);
  const softwareCategoryIcon = findIcon(table["Sub Industry"], table["Sub Industry"]);
  const deploymentIcon = table['Deployment'] === 'On-Prem/Hybrid' ? <FaServer/>:<FaCloud/>
  const openSourceIcon = table['Tech Stack'] === 'Open Source' ? <FaGitAlt/>:<FaUserSecret/>
  return(
    
  <Box sx ={{maxWidth:'100%',height:'100%', display: 'flex', flexDirection: 'column' }}>
    <Box
      sx={{
        backgroundColor: theme.background.backgroundColor,
        marginBottom: '12px', // Ensure no gap
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: theme.palette.mode === 'light'
      ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
      : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
      }}
      elevation={0} // No default elevation, custom shadow
    >
      <Collapse in={open} sx={{ width: '100%' }}>
          <Box sx={{ position: 'relative', marginTop:'0px',paddingLeft: '0%', marginLeft: '0px' }}>
                <Box elevation={0} sx={{ paddingBottom: '4px', maxWidth: '100%', paddingTop: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Box sx={{ display: 'flex', width:'100%',flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

          
          <Box
            elevation={theme.palette.mode == 'dark' ? 0 : 0}
            sx={{
              border: '0px solid lightgrey',
              width: '100%',
              marginLeft: '6px',
              paddingTop: '4px',
              marginTop: '0px',
              paddingLeft: '6px',
              paddingRight: '8px',
              paddingBottom: '0px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >

        </Box>


         </Box>



          <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '10%',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        </Box>
        </Collapse>
        

    </Box>
    <Box sx={{display:'flex',justifyContent:'center'}}>
      <Box>
    {!isMobile&&<Box  sx={{flexWrap:'wrap',marginLeft:'1%', paddingBottom: '4px', maxWidth: tableContainerWidth + 50, paddingTop: '0px', marginTop: '6px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Paper sx= {{width:'200px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'14px', fontWeight:600,textAlign: 'center' }} variant='body1'>
        Software Category
        </Typography>
      <Box sx={{display:'flex',height:'50px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      {softwareCategoryIcon && <Icon sx={{}}>{softwareCategoryIcon}</Icon>}
      <Typography sx={{fontSize:'14px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {table["Sub Industry"]} Software
        </Typography>
        </Box>
      </Paper>
      <Paper sx= {{width:'200px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'14px', fontWeight:600,textAlign: 'center' }} variant='body1'>
        Market Category
        </Typography>
      <Box sx={{display:'flex',height:'50px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      {categoryIcon && <Icon sx={{}}>{categoryIcon}</Icon>}
      <Typography sx={{fontSize:'14px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {table["Category"]}
        </Typography>
        </Box>
      </Paper>
      <Paper sx= {{width:'200px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'14px', fontWeight:600,textAlign: 'center' }} variant='body1'>
        Deployment Model
        </Typography>
      <Box sx={{display:'flex',height:'50px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      { <Icon sx={{}}>{deploymentIcon}</Icon>}
      <Typography sx={{fontSize:'14px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {table["Deployment"]}
        </Typography>
        </Box>
      </Paper>
      <Paper sx= {{width:'200px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'14px', fontWeight:600,textAlign: 'center' }} variant='body1'>
      Source Model
        </Typography>
      <Box sx={{display:'flex',height:'50px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      { <Icon sx={{}}>{openSourceIcon}</Icon>}
      <Typography sx={{fontSize:'14px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {table["Tech Stack"]}
        </Typography>
        </Box>
      </Paper>
      

      <Box>

      </Box>
   
        </Box>}
    <Box sx={{ maxWidth: tableContainerWidth + 50,marginLeft:'1%',display: 'flex',justifyContent: 'flex-start',flexWrap: shouldWrap2 ? 'wrap' : 'nowrap'}}>
    <Box  sx={{ paddingTop:'5px',paddingBottom:'5px',flex:1,maxWidth: shouldWrap2 ? '100%' : '280px', minWidth:'350px',marginRight:'.5%',marginTop:'6px',paddingLeft:'1.5%',paddingRight:'1.5%'}}>
    <Box sx={{ 
      flex: 1, display: 'flex', 
      justifyContent:'space-between',
      paddingBottom: '6px', 
      maxWidth: "98%", 
      color: theme.palette.primary.main, 
      fontSize: '24px', 
      fontWeight: '300 ' , // Use !important to enforce the style
      textAlign: 'left', // Ensure text is left-aligned
      paddingLeft: '0.5%',
  }}
  style={{ fontWeight: '300' }} // Inline style for testing
  >Company Statistics</Box>
      <TableContainer component={Paper} sx={{ marginTop:'10px',maxWidth:'300px',marginTop:'5px'}}>
        <Table size='small' aria-label="a dense table">
          <TableHead>
          <TableRow sx={{backgroundColor: theme.tableHeader.color,fontWeight: '700',fontSize: '30'}}>
              <TableCell  sx={{paddingTop: '3px',paddingBottom: '3px'}} align="left">Metrics</TableCell>
              <TableCell sx={{paddingTop: '3px',paddingBottom: '3px'}} align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
        {keyStats.map((row, index) => (
          <TableRow
            key={row.field}
            sx={{
              backgroundColor: index % 2 === 0 ? theme.tableHeader.highlight : theme.background.default,
              '&:last-child td, &:last-child th': { border: 0 },
              position: 'relative',
              ':hover': {
                backgroundColor: theme.tableHeader.highlightDark,
                cursor: 'pointer',
              },
            }}
          >
            <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="left">
              {row.field}
              {row.subField && <div className="sublabel">{row.subField}</div>}
            </TableCell>
            <TableCell sx={{ paddingTop: '3px', paddingBottom: '3px' }} align="right">{row.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
        </Table>
      </TableContainer>
      </Box>
        
        <Paper elevation={1} sx={{  flex: 1,paddingTop:'5px',marginTop:'6px',paddingLeft:'1%',minWidth:'300px',maxWidth:'100%'}}>
        <Box sx={{ 
          flex: 1, display: 'flex', 
          justifyContent:'space-between',
          paddingBottom: '6px', 
          maxWidth: "98%", 
          color: theme.palette.primary.main, 
          fontSize: '24px', 
          fontWeight: '300 ' , // Use !important to enforce the style
          textAlign: 'left', // Ensure text is left-aligned
          paddingLeft: '0.5%',
      }}
      style={{ fontWeight: '300' }} // Inline style for testing
      >Company Description </Box>
        

          
          <Box style={{ flex: 1,width:'100%', position: 'relative' }}> 
          <Box style={{ flex: 1,width:'100%', position: 'relative',paddingBottom:'8px',paddingRight:'7%',paddingLeft:'2%' }}> 
            
            {descriptionParagraphs.map((paragraph, index) => (
                <Typography key={index} variant="body1" sx={{fontStyle:'italic'}} paragraph>
                  {paragraph}
                </Typography>
              ))}
          </Box>
        </Box>
        </Paper>
        </Box>
        </Box>
        </Box>
    
  </Box>
      )}
      export default SecurityAnalysisAbout;